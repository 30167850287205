// @codekit-append "../node_modules/foundation-sites/dist/js/foundation.min.js";

jQuery(document).ready(function() {
    jQuery(document).foundation();

    jQuery(document).on("click", "#aussendienst_ja", function() {
      jQuery("#aussendienst").prop( "checked", false );
    });
    jQuery(document).on("click", "#aussendienst", function() {
      jQuery("#aussendienst_ja").prop( "checked", false );
    });

    jQuery(document).on("click", "#datenschutz_ja", function() {
      jQuery(this).next().removeClass("error");
      jQuery("#datenschutz").prop( "checked", false );
    });
    jQuery(document).on("click", "#datenschutz", function() {
      jQuery("#datenschutz_ja").prop( "checked", false );
    });

    jQuery( "select" ).change(function() {
      jQuery(this).addClass("changed");
    });

    jQuery(document).on("click", ".submit", function() {
        var status = true;
        jQuery(".error-message").hide();

        jQuery.each(jQuery(".req"), function() {
            var $obj = jQuery(this);
            $obj.removeClass("error");

            if(($obj.attr("type") == "text" && $obj.val() == "") || ($obj.attr("type") == "email" && $obj.val() == "")) {
                $obj.addClass("error");
                status = false;
            }
        });
        if(!jQuery("#datenschutz_ja").is(":checked")) {
          jQuery("#datenschutz_ja").next().addClass("error");
          status = false;
        } else {
          jQuery("#datenschutz_ja").next().removeClass("error");
        }

        if(jQuery("#anrede option:selected").val() == "0") {
          jQuery("#anrede").addClass("error");
          status = false;
        } else {
          jQuery("#anrede").removeClass("error");
        }



        if(!status) {
            jQuery(".error-message").html("Bitte füllen Sie alle markierten Felder aus.").fadeIn();
        } else {

            var anrede         = jQuery('#anrede option:selected').val();
            var firma         = jQuery('#firma').val();
            var bereich       = jQuery('#bereich').val();
            var name          = jQuery('#name').val();
            var strasse       = jQuery('#strasse').val();
            var telefon       = jQuery('#telefon').val();
            var mobil         = jQuery('#mobil').val();
            var ort         = jQuery('#ort').val();
            var email         = jQuery('#email').val();
            var datenschutz   = jQuery('#datenschutz_ja').is(":checked") ? 1 : 0;
            var aussendienst  = jQuery('#aussendienst_ja').is(":checked") ? 1 : 0;

            var mailData = 	{
                "anrede": anrede,
                "firma": firma,
                "bereich": bereich,
                "name": name,
                "strasse": strasse,
                "telefon": telefon,
                "mobil": mobil,
                "ort": ort,
                "email": email,
                "datenschutz": datenschutz,
                "aussendienst": aussendienst
            };


            console.log(mailData);


            jQuery.ajax({
                type: "POST",
                url: "anmeldung.php",
                data: mailData,
                cache: false,
                dataType: 'json',
                beforeSend: function() {
                    jQuery(".submit").fadeOut("fast");
                },
                success: function(data) {

                    if(data.status) {
                        jQuery(".success-message").html("Ihre Anmeldung wurde erfolgreich gespeichert. Sie erhalten in Kürze eine E-Mail mit einem Bestätigungslink.").fadeIn("fast");
                    } else {

                        jQuery(".error-message").html(data.message).fadeIn("fast");
                        jQuery(".submit").fadeIn("fast");
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    jQuery(".error-message").html(errorThrown).fadeIn("fast");
                    jQuery(".submit").fadeIn("fast");
                }
            });

        }
    });
});
